import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#0C8533',
            secondary: '#243C57',
            tertiary: '#F1BA27',
            accent: '#E9D2B6',
            error: '#f55a4e',
            info: '#00d3ee',
            success: '#5cb860',
            warning: '#ffa21a'
          },
        },
      }
});
