<template>
  <v-container>
  <v-row>
          <v-toolbar flat dense>
            <v-btn small @click="dlToCsv()"><v-icon>mdi-download</v-icon>Download CSV</v-btn>
            <v-spacer />
              <span style="width:300px">
                  <v-select
                  outlined
                  v-model="reporttype"
                  dense
                  hide-details
                  label="View Report"
                
                  :items="report_types"
                  ></v-select>
              </span>
            
              <!-- <v-btn small outlined color="success" @click="today()">Today</v-btn>
              <v-btn small outlined color="purple" class="ml-2">Last 7 Days</v-btn> -->
              <span style="width:150px;" class="ml-2">
              
              <v-menu
                        v-model="from_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="From Date"
                                :value="from_date"
                                filled
                                readonly
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                hide-details
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="from_date" no-title scrollable @input="from_menu=false" />
                    </v-menu>

          </span>
            
              <span style="width:150px;" class="ml-2">
              
                  <v-menu
                            v-model="to_menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field 
                                    label="To Date"
                                    :value="to_date"
                                    filled
                                    readonly
                                    dense
                                    outlined
                                    append-icon="mdi-calendar"
                                    hide-details
                                    v-on="on"
                                />
                            </template>
                            <v-date-picker v-model="to_date" no-title scrollable @input="to_menu=false, getItems()" />
                        </v-menu>

              </span>
          </v-toolbar>

  </v-row>
  <v-row>
    
          <v-col cols="12">
            <div id="ta_wrap">
              <table class="va-table va-table-border-bot text-body-2" id="to-dl-table">
                  <tr>
                      <th colspan="13" class="text-left text-success">Tourist: {{reporttype}} |    {{$moment(from_date).format("MMM, DD YYYY")}} -  {{$moment(to_date).format("MMM, DD YYYY")}} | Count: {{$count(guests.length)}}</th>
                     
                    
                  </tr>
                  <tr>
                      <!-- <th class="text-center">NO.</th> -->
                      <th class="text-left">FULL NAME</th>
                      <th class="text-left">TOURIST TYPE</th>
                      <th class="text-left">COUNTRY</th>
                      <th class="text-left">REGION</th>
                      <th class="text-left">PROVINCE</th>
                      <th class="text-left">MUNICIPALITY/CITY</th>
                      <th class="text-left">MODE OF TRAVEL</th>
                      <th class="text-left">PURPOSE OF TRAVEL</th>
                      <th class="text-left">A/N</th>
                      <th class="text-left">NIGHTS</th>
                      <th class="text-left">HOTELS</th>
                      <th class="text-left">PAX NO</th>
                      <th class="text-left">GENDER</th>
                      <th class="text-left">AGE GROUP</th>
                  </tr>
                  <tr v-for="(item, index) in FILTERDATA" :key="index">
                      <td class="text-left">{{ item.lastname }}, {{ item.firstname }}</td>
                      <td class="text-left">{{item.class}}</td>
                      <td class="text-left">{{item.country}}</td>
                      <td class="text-left">{{item.region}}</td>
                      <td class="text-left">{{item.province}}</td>
                      <td class="text-left">{{item.city}}</td>
                      <td class="text-left">{{item.travel_mode}}</td>
                      <td class="text-left">{{item.travel_purpose}}</td>
                      <td class="text-left">{{item.no_of_nights}}</td>
                      <td class="text-left">{{item.no_of_nights}}</td>
                      <td class="text-left">{{item.stay_in}}</td>
                      <td class="text-left">1</td>
                      <td class="text-left">{{item.gender}}</td>
                      <td class="text-left">{{age(item.age)}}</td>
                  </tr>
              </table>
            </div>
          </v-col>
   </v-row>   
 </v-container> 
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      from_menu: false,
    from_date:'',
    to_menu: false,
    to_date:'',
    guests:[],
    report_types: ['ALL','DOMESTIC', 'FOREIGN', "OFW"],
      reporttype:"ALL"      
    }),
    created() {
      if (this.$session.exists()) {
      this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
      this.setLoggedIn(true)
      this.setAppBar(true)
      this.today()
      } 
    },
    computed: {
     
      FILTERDATA(){
          if(this.reporttype =="ALL") {
            return this.guests
          }
          var items = this.guests.reduce((res,item)=>{
            if(item.class==this.reporttype) {
              res.push(item)
            }
            return res
          },[])
          return items
      
      },
      TOTAL(){
          if(this.guests.length == 0){
              return 0
          }
         // var total = 0
          var total = this.guests.reduce((res,i)=>{
                  res += this.$IsNum(i.price_charge)
                  return res
          }, 0)
          return total
      }
    },
    methods: {
      ...mapMutations(['setLoggedIn', 'setAppBar']),
      age(_age) {
        if(_age< 6) {
             return "CHILD"
        } else if (_age> 59) {
          return "SENIOR"
        } 
        return "ADULT"
      },
     today(){
      this.from_date =  this.$moment().format("YYYY-MM-DD")
      this.to_date =  this.$moment().format("YYYY-MM-DD")
      this.getItems()
     },

      getItems(){
        this.guests = []
          console.log({from_date: this.from_date, to_date: this.to_date, qry_str: this.reporttype})
          this.$http.post("report/daily_arrival",{from_date: this.from_date, to_date: this.to_date, qry_str: "Tourism"}).then(response => {
              console.log(response.data.guests)
          response.data.guests!=null?this.guests = response.data.guests:this.guests = []
         
        }).catch(e => {
            this.NETWORK_ERROR(e)
       })
      },

      dlToCsv(){
          this.DownloadToCsv("to-dl-table", this.reporttype +'-Tourism-reports-' + this.from_date + '-' + this.to_date) 
      }

    },
  }
</script>