<template>
  <v-navigation-drawer
    v-if="$store.state.is_logged"
    v-model="$store.state.drawer"
  
    app
    permanent
    :mini-variant.sync="mini"
  >
    <v-list-item>
      <v-list-item-avatar>
        <v-img :src="$store.state.app_icon" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>CPANEL</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list class="grow">
      
      <template v-for="(link,i) in mymenu">
        <template v-if="!link.child">
          <v-list-item
            :key="i"
            @click="$router.push(link.route).catch(err => {})"
          >
            <v-list-item-title>
              <v-icon
                left
                color="primary"
              >
                {{ link.icon }}
              </v-icon> {{ link.label }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <template v-else>
          <v-menu
            :key="i"
            transition="slide-x-transition"
            bottom
            right
            :offset-x="offset"
            rounded="0"
          >
            <template #activator="{ on, attrs }">
              <v-list-item
                :key="i"
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-title>
                  <v-icon
                    left
                    color="primary"
                  >
                    {{ link.icon }}
                  </v-icon> 
                  <span>{{ link.label }}</span>
                </v-list-item-title>
              </v-list-item>
            </template>
            <v-list
              color="primary"
              dark
            >
              <v-list-item
                v-for="(item, index) in link.childs"
                :key="index"
                @click="$router.push(item.route).catch(err => {})"
              > 
                <v-list-item-title>
                  <v-icon left>
                    {{ item.icon }}
                  </v-icon> {{ item.label }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
  export default {
    data () {
      return {
          offset: true,
          mini: true,
          tourism_menu: [
          {
            label: 'Main', icon: 'mdi-home', route: '/main', child: false,
          },
         
          //  {
          //   label: 'Branches', icon: 'mdi-store', route: '/accounts', child: false,
          // },
          {
            label: 'Guest Finder', icon: 'mdi-account-search', route: '/guest-finder', child: false,
          },
          {
            label: 'Arrivals', icon: 'mdi-monitor-account', route: '/arrivals', child: false,
          },
        
          {
            label: 'Tourism Reports', icon: 'mdi-chart-box-multiple-outline', route: '/tourism-reports',
          },
          
        ],
        accounting_menu: [
          {
            label: 'Main', icon: 'mdi-home', route: '/main', child: false,
          },
         
          //  {
          //   label: 'Branches', icon: 'mdi-store', route: '/accounts', child: false,
          // },
          // {
          //   label: 'Guest Finder', icon: 'mdi-account-search', route: '/guest-finder', child: false,
          // },
          {
            label: 'Arrivals', icon: 'mdi-monitor-account', route: '/arrivals', child: false,
          },
          // {
          //   label: 'Hotels', icon: 'mdi-office-building-marker', route: '/hotels', child: false,
          // },
          // {
          //   label: 'Accounts', icon: 'mdi-account-cog', route: '/accounts', child: false,
          // },
          //  {
          //   label: 'Settings', icon: 'mdi-cogs', route: '/settings', child: false,
          // },
          {
            label: 'Reports', icon: 'mdi-chart-line', route: '/inventory', child: true,
            childs: [
              {
                label: 'Treasury Reports', icon: 'mdi-chart-tree', route: '/treasury-reports',
              },
              {
                label: 'Tourism Reports', icon: 'mdi-chart-box-multiple-outline', route: '/tourism-reports',
              },
              {
                label: 'Settlement', icon: 'mdi-chart-box-multiple-outline', route: '/settlement-reports',
              },
              // {
              //   label: 'Statistics', icon: 'mdi-chart-box-multiple-outline', route: '/inventory',
              // },
             
            ],
          },
        ],
        links: [
          {
            label: 'Main', icon: 'mdi-home', route: '/main', child: false,
          },
         
          //  {
          //   label: 'Branches', icon: 'mdi-store', route: '/accounts', child: false,
          // },
          {
            label: 'Guest Finder', icon: 'mdi-account-search', route: '/guest-finder', child: false,
          },
          {
            label: 'Arrivals', icon: 'mdi-monitor-account', route: '/arrivals', child: false,
          },
          // {
          //   label: 'Expected Arrivals', icon: 'mdi-monitor-shimmer', route: '/expected-arrivals', child: false,
          // },
          // {
          //   label: 'Hotels', icon: 'mdi-office-building-marker', route: '/hotels', child: false,
          // },
          {
            label: 'Accounts', icon: 'mdi-account-cog', route: '/accounts', child: false,
          },
           {
            label: 'Settings', icon: 'mdi-cogs', route: '/settings', child: false,
          },
          {
            label: 'Reports', icon: 'mdi-chart-line', route: '/inventory', child: true,
            childs: [
              {
                label: 'Treasury Reports', icon: 'mdi-chart-tree', route: '/treasury-reports',
              },
              {
                label: 'Tourism Reports', icon: 'mdi-chart-box-multiple-outline', route: '/tourism-reports',
              },
              {
                label: 'Settlement', icon: 'mdi-chart-box-multiple-outline', route: '/sp-settlement',
              },
              // {
              //   label: 'Statistics', icon: 'mdi-chart-box-multiple-outline', route: '/inventory',
              // },
             
            ],
          },
        ],
        adminlinks: [
          {
            label: 'Main', icon: 'mdi-home', route: '/main', child: false,
          },
         
          //  {
          //   label: 'Branches', icon: 'mdi-store', route: '/accounts', child: false,
          // },
          {
            label: 'Guest Finder', icon: 'mdi-account-search', route: '/guest-finder', child: false,
          },
          {
            label: 'Arrivals', icon: 'mdi-monitor-account', route: '/arrivals', child: false,
          },
          // {
          //   label: 'Hotels', icon: 'mdi-office-building-marker', route: '/hotels', child: false,
          // },
          {
            label: 'Accounts', icon: 'mdi-account-cog', route: '/accounts', child: false,
          },
           {
            label: 'Settings', icon: 'mdi-cogs', route: '/settings', child: false,
          },
          {
            label: 'Reports', icon: 'mdi-chart-line', route: '/inventory', child: true,
            childs: [
              {
                label: 'Treasury Reports', icon: 'mdi-chart-tree', route: '/treasury-reports',
              },
              {
                label: 'Tourism Reports', icon: 'mdi-chart-box-multiple-outline', route: '/tourism-reports',
              },
              {
                label: 'Settlement', icon: 'mdi-chart-box-multiple-outline', route: '/settlement-reports',
              },
              // {
              //   label: 'Statistics', icon: 'mdi-chart-box-multiple-outline', route: '/inventory',
              // },
             
            ],
          },
        ],
      }
    },
    computed: {
      mymenu(){
        let role = this.myrole
        if(role == "Tourism") {
          return this.tourism_menu
        } else if(role == "Accounting") {
          return this.accounting_menu
        }  else if(role == "Accounting") {
          return this.accounting_menu
        }  else {
          if(this.LOGIN_USER() == 'ICT Malay') {
            return this.adminlinks
          }
          return this.links
        }
      },
      myrole() {
       return  this.$session.get('role')
     },
    }
  }
</script>