<template>
    <div>
      
        <!-- <table class="va-table va-table-border-bot text-body-2">
                    <tr>
                        <th class="text-left">Month</th>
                        <th>Foreign</th>
                        <th>Local</th>
                        <th>OFW</th>
                        <th>Total</th>
                    </tr>
                    <tr>
                        <td class="text-left">January</td>
                        <td class="text-center">1</td>
                        <td class="text-center">4</td>
                        <td class="text-center">8</td>
                        <td class="text-center">{{$count(30)}}</td>
                    </tr>
                    
                </table> -->
    </div>
</template>
<script>
  import {
    mapMutations
  } from 'vuex'
    export default {
      data: () => ({
        touristtype:"Local and OFW"
      }),
     
      methods: {
       
        
      },
    }
  </script>