import TableCSVExporter from '@/plugins/tabletocsv'
import {
    mapMutations
  } from 'vuex'
export const API_MIXINS = {
    methods: {
        ...mapMutations([
            'setMonthDailySales',
            'setCredits',
            'setAlert',
            'setAlertColor', 
            'setAlertText', 
            'setLoggedIn'
            ]),
            STATUS_COLOR(status){
                switch(status) {
                   case "Verified":
                     return "success"
                   case "For Verification":
                     return "#757575"
                   case "Booked":
                     return "text-success"
                   case "Cancelled":
                     return "text-gray"  
                   case "Ready":
                     return "text-info"
                     case "Open":
                       return "text-sucess"
                   default:
                     return ""
                }
             },
             LOGIN_USER() {
              return this.$session.get('fullname')
            },     
        VA_ALERT(color, message) {
            this.setAlertText(message)
            this.setAlertColor(color)
            this.setAlert(true)
        },
        
        NETWORK_ERROR(e){
            if(e.response == undefined) {
                    this.VA_ALERT('Error', "Server Unreachable!")
            } else {
                this.VA_ALERT('error', "No avaialable this time!")
            }
          },

          DownloadToCsv (eleID, filename) {
            const dataTable = document.getElementById(eleID)
            const exporter = new TableCSVExporter(dataTable)
            const csvOutput = exporter.convertToCSV()
            const csvBlob = new Blob([csvOutput], { type: 'text/csv' })
            const blobUrl = URL.createObjectURL(csvBlob)
            const anchorElement = document.createElement('a')
      
            anchorElement.href = blobUrl
            anchorElement.download =  filename+ '.csv'
            anchorElement.click()
      
            setTimeout(() => {
              URL.revokeObjectURL(blobUrl)
            }, 500)
          } 
    } ///eof methods
}