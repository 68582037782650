/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
    {
      path: '/login',
      name: 'Login',
      view: 'Login'
    },
    {
      path: '/main',
      name: 'Main',
      view: 'Main'
    },
    {
      path: '/treasury',
      name: 'Treasury',
      view: 'Treasury'
    },
    {
      path: '/tourism-reports',
      name: 'Tourism Reports',
      view: 'reports/Tourism'
    },
    {
      path: '/arrivals',
      name: 'Tourist Arrivals',
      view: 'reports/Arrivals'
    },
    {
      path: '/treasury-reports',
      name: 'Treasury Reports',
      view: 'reports/Treasury'
    },
    {
      path: '/settlement-reports',
      name: 'Settlement Reports',
      view: 'reports/Settlement'
    },
    {
      path: '/sp-settlement',
      name: 'Surepay Settlement',
      view: 'reports/Surepay'
    },
    {
      path: '/inventory',
      name: 'Inventory',
      view: 'Inventory'
    },
    {
      path: '/accounts',
      name: 'Account Management',
      view: 'AccountPage'
    },
    {
      path: '/branches',
      name: 'Branch Management',
      view: 'BranchPage'
    },
    {
      path: '/settings',
      name: 'Settings',
      view: 'SettingsPage'
    },
    {
      path: '/hotels',
      name: 'Hotels/Resorts Management',
      view: 'Hotels'
    },
    {
      path: '/guest-finder',
      name: 'Guest Finder',
      view: 'GuestFinder'
    }
  ]
  