<template>
    <div>
        <v-toolbar dense class="mb-4" >
            <v-btn small @click="dlToCsv()"><v-icon>mdi-download</v-icon>Download CSV</v-btn>
            <v-spacer></v-spacer>
            <span style="width:200px;" class="ml-2">
                
                <v-menu
                          v-model="to_menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                      >
                          <template v-slot:activator="{ on }">
                              <v-text-field 
                                  label="Select Date"
                                  :value="selected_date"
                                  filled
                                  readonly
                                  dense
                                  outlined
                                  append-icon="mdi-calendar"
                                  hide-details
                                  v-on="on"
                              />
                          </template>
                          <v-date-picker v-model="selected_date" type="month" no-title scrollable @input="to_menu=false, getItems()" />
                      </v-menu>

            </span>
        </v-toolbar>
<v-divider class="mb-4"/>
        <table class="va-table va-table-border-bot text-body-2" id="to-dl-table">
                    <tr>
                        <th colspan="3" class="tex-left">{{$moment(selected_date).format("MMMM-YYYY")}}</th>
                    </tr>
                    <tr>
                        <th>No.</th>
                        <th>Country Of Origin</th>
                        <th>Number of Pax</th>
                    </tr>
                    <tr v-for="(item, index) in SORTDATA" :key="index">
                        <td class="text-center">{{index+1}}</td>
                        <td class="text-center">{{item.label}}</td>
                        <td class="text-center">{{$count(item.number)}}</td>
                    </tr>
                    
                </table>
    </div>
</template>
<script>
  import {
    mapMutations
  } from 'vuex'
    export default {
        props: {
         
            reporttype: {
            type: String,
            default: "new"
            },
        },
      data: () => ({
        from_date:'',
      selected_date:"",
      to_menu: false,
      to_date:'',
      guests:[]
      }),
      created(){
        if (this.$session.exists()) {
            this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
            this.selected_date = this.$moment().format("YYYY-MM")
            this.getItems()
        } 
      },
     computed:{
        SORTDATA(){
            var objectArr = this.GROUPDATA
            objectArr.sort(function(a, b) {
                return b.number - a.number;
            });
          return objectArr  
        },
        GROUPDATA(){
            if(this.reporttype == "Domestic Tourist") {
                return this.DOMESTIC
            } else {
                return this.FOREIGN
            }
           
        },
        DOMESTIC(){
            var dataArr = []
           // var items = []
            var refNos = this.$groupBy('region')
            for (const [key, value] of Object.entries( refNos(this.guests))) {
                    var item = { region: key, data:value}
                   dataArr.push(item)
            }

            var items = dataArr.reduce((res,item)=>{
                res.push({label: item.region, number: item.data.length})
                return res
            }, [])

            return items
        },
        FOREIGN(){
            var dataArr = []
           // var items = []
            var refNos = this.$groupBy('country')
            for (const [key, value] of Object.entries( refNos(this.guests))) {
                    var item = { country: key, data:value}
                   dataArr.push(item)
            }

            var items = dataArr.reduce((res,item)=>{
                res.push({label: item.country, number: item.data.length})
                return res
            }, [])

            return items
        }
     },
      methods: {
        getItems(){
            this.from_date = this.$moment(this.selected_date).format("YYYY")
            this.to_date = this.$moment(this.selected_date).format("MM")
            this.$http.post("report/daily_arrival",{from_date: this.from_date, to_date: this.to_date, qry_str: this.reporttype}).then(response => {
            console.log(response.data)
            response.data.guests!=null?this.guests = response.data.guests:this.guests = []
           
          }).catch(e => {
              this.NETWORK_ERROR(e)
         })
        },
        dlToCsv(){
            this.DownloadToCsv("to-dl-table", this.reporttype +'-' + this.from_date)
        }

      },
    }
  </script>