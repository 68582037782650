<template>
  <v-dialog
    v-model="dailog"
    width="540"
    persistent
  >
  <v-form  
              
              ref="form"
              v-model="valid"
              autocomplete="off"
              lazy-validation
            >
    <v-card >
      <v-card-title
        class="text-h6"
        dense
      >
        <v-icon left color="success">
          mdi-account
        </v-icon> Account Info
        <v-spacer />
        <v-btn
          color="success"
          text
          dense
          @click="emitToParent('close')"
        >
          Close
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="elevation-0 pa-6">
    
         
              <v-text-field
                  v-model="account.firstname"
                  :rules="nameRules"
                    required
                    label="First Name"
                    color="success"
                    dense
                    outlined
                  />
                  <v-text-field
                  v-model="account.lastname"
                  :rules="nameRules"
                    required
                    label="Last Name"
                    color="success"
                    dense
                    outlined
                  />
                  <v-text-field
                  v-model="account.mobile_no"
                  :rules="nameRules"
                    required
                    label="Contact No."
                    color="success"
                    dense
                    prepend-inner-icon="mdi-cellphone-basic"
                    outlined
                  />  
                  <v-text-field
                  v-model="account.email"
                  :rules="emailRule"
                    required
                    label="Email"
                    color="success"
                    dense
                    prepend-inner-icon="mdi-email"
                    outlined
                  />
                  <v-autocomplete
                  v-model="account.role"
                  :rules="nameRules"
                    required
                    label="Role"
                    color="success"
                    dense
                    :items="roles"
                    outlined
                  />

                  <v-autocomplete
                  v-if="account.role=='Verifier'"
                  v-model="account.reference"
                  :rules="nameRules"
                    required
                    label="Task to Verify"
                    color="success"
                    dense
                    :items="products"
                    item-text="name"
                    item-value="sku"
                    outlined
                  />
                  
                  <v-autocomplete
                  v-model="account.status"
                  :rules="nameRules"
                    required
                    label="Status"
                    color="success"
                    dense
                    :items="['Verified', 'Deactivate']"
                    outlined
                  />
      
            <div class="mt-2 mb-2">
                  Login Credentials
                <v-divider/>
              </div>
              <v-text-field
                  v-model="account.username"
                  :rules="nameRules"
                    required
                    label="Username"
                    color="success"
                    dense
                    prepend-inner-icon="mdi-account"
                    outlined
                  />
                  <template v-if="state=='update'">
                  <v-btn  v-if="!changepass" small tile @click="changepass= !changepass">Change Password</v-btn>
                  <template v-else>
                  <v-text-field
                    v-model="password"
                    :rules="nameRules"
                    required
                    type="password"
                    outlined
                    prepend-inner-icon="mdi-key"
                    label="Password"
                    dense
                  />
                  <v-text-field
                  v-model="password2"
                  :rules="confirmPasswordRules"
                    required
                    type="password"
                    dense
                    outlined
                    prepend-inner-icon="mdi-key"
                    label="Confirm Password"
                  />
                  <v-btn  small tile @click="changepass= !changepass" class="mr-2">Not Now</v-btn>
                  <v-btn  small tile @click="changePassword()">Save Password</v-btn>
                </template>
              </template>
              <template v-if="state=='new'">
                <v-text-field
                    v-model="account.password"
                    :rules="nameRules"
                    required
                    type="password"
                    outlined
                    prepend-inner-icon="mdi-key"
                    label="Password"
                    dense
                  />
                  <v-text-field
                  v-model="password2"
                  :rules="newConfirmPasswordRules"
                    required
                    type="password"
                    dense
                    outlined
                    prepend-inner-icon="mdi-key"
                    label="Confirm Password"
                  />
              </template>
           

            
      </v-card-text>
      <v-card-actions>
                  <v-spacer/>
                  <v-btn small tile color="success" @click="validate()">{{state=='new'?'Add': 'Save'}}</v-btn>
                </v-card-actions>
    </v-card>
  </v-form> 
  </v-dialog>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    props: {
    show: {
      type: Boolean,
      default: false
    },
    state: {
      type: String,
      default: "new"
    },
    data: {
      type: Object,
      default: ()=>{}
    }
    
    },
    data: () => ({
      valid: true,
      loading: false,
      products: [],
      roles: ["Verifier", "Accounting", "Tourism", "Help Desk", "Agency",  "Administrator"],
        dailog: false,
        account: {},
        nameRules: [
        v => !!v || 'Field is required'
      ],
      emailRule: [
        v => !!v || "Field is required",
        v => /.+@.+\..+/.test(v) || "Invalid email",
      ],
      changepass: false,
      email: "", 
      password: "", 
      password2: "", 
    }),
    watch: {
        show(v) {
          v?this.dailog=true:this.dailog=false
          if(v) {
            this.state != 'new'? this.account = this.data:this.account={}
            this.getProducts()
          }
        }
    },
    created() {
    },
    computed:{
  
  confirmPasswordRules() {
    return [
      v => !!v || 'Confirm Password is required',
      v => v === this.password || 'Passwords do not match',
    ];
  },
  newConfirmPasswordRules() {
    return [
      v => !!v || 'Confirm Password is required',
      v => v === this.account.password || 'Passwords do not match',
    ];
  },
},
methods: {
  ...mapMutations(['setLoggedIn', 'setAppBar']),
  getProducts() {
          this.$http.post("product/get",{}).then(response => {
            response.data.status?this.products = response.data.products:this.products = []
           
          }).catch(e => {
              this.NETWORK_ERROR(e)
         })
    
  },
  emitToParent (action) {
        this.$emit('DialogEvent', {action: action})
      },

      validate () {
        if (this.$refs.form.validate()) {
          this.state=='new'?this.newUser(): this.updateUser()
        }
         
      },
  changePassword() {
     console.log(this.password)
     this.$http.post("account/changepassword",{id: this.account.id ,password: this.password}).then(response => {
      response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
      this.changepass = !this.changepass
      this.password = ""
      this.password2 = ""
      
      
     }).catch(e => {
         this.NETWORK_ERROR(e)
    })
 },
 newUser() {
  this.account.firstname = this.$Capitalize(this.account.firstname)
    this.account.lastname = this.$Capitalize(this.account.lastname)
    this.account.username =this.account.username.trim()
     this.$http.post("user/new",this.account).then(response => {
      response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
      if(response.data.status) {
        this.emitToParent('close')
      }
     }).catch(e => {
         this.NETWORK_ERROR(e)
    })
 },
 updateUser() {
  this.account.firstname = this.$Capitalize(this.account.firstname)
    this.account.lastname = this.$Capitalize(this.account.lastname)
    this.account.username =this.account.username.trim()
     this.$http.post("user/update",this.account).then(response => {
      response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
      this.emitToParent('close')
     }).catch(e => {
         this.NETWORK_ERROR(e)
    })
 }
},
  }
</script>
