import { render, staticRenderFns } from "./tourist-arrival.vue?vue&type=template&id=522eb757&"
import script from "./tourist-arrival.vue?vue&type=script&lang=js&"
export * from "./tourist-arrival.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports