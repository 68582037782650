<template>
  <v-dialog
    v-model="dailog"
  fullscreen
    persistent
  >

    <v-card>
      <v-toolbar dense>
        Today's Expected Guests
        <v-spacer/>
        <v-btn small tile color="success" @click="emitToParent('close')">close</v-btn>
        </v-toolbar>
      <v-card-text>
        <div class="mt-4"><v-btn small @click="dlToCsv()"><v-icon>mdi-download</v-icon>Download CSV</v-btn></div>
        
        <table class="va-table va-table-border-bot text-body-2 mt-4" id="to-dl-table">
                
                  <tr>
                      <th class="text-left">NO.</th>
                      <th class="text-left">FULL NAME</th>
                      <th class="text-left">TOURIST TYPE</th>
                      <th class="text-left">ORIGIN</th>
                      <th class="text-left">GENDER</th>
                      <th class="text-left">AGE</th>
                  </tr>
                  <tr v-for="(item, index) in guests" :key="index">
                    <td class="text-left">{{index +1}}</td>
                    <td class="text-left">{{ item.lastname }}, {{ item.firstname }}</td>
                      <td class="text-left">{{item.class}}</td>
                      <td class="text-left">{{item.country}}</td>
                      <td class="text-left">{{item.gender}}</td>
                      <td class="text-left">{{item.age}}</td>
                  </tr>
              </table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    props: {
    show: {
      type: Boolean,
      default: false
    },
    guests: {
      type: Array,
      default: ()=>[]
    }
    
    },
    data: () => ({
      valid: true,
      loading: false,
        dailog: false,
     
    }),
    watch: {
        show(v) {
          v?this.dailog=true:this.dailog=false
         
        }
    },
methods: {
  ...mapMutations(['setLoggedIn', 'setAppBar']),
  emitToParent (action) {
        this.$emit('DialogEvent', {action: action})
      },
      dlToCsv(){
         var to_date =this.$moment().format("YYYY-MM-DD")
          this.DownloadToCsv("to-dl-table", 'TODAYS-EXPECTED-'+ to_date) 
      }
 },
 
  }
</script>
